body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
.selectbox-border {
	border: 1px solid #0051ba !important;
	border-radius: 5px;
}
.common-button {
	background-color: #f36b21 !important;
	color: white !important;
}
.login-button {
	background-color: #c9b2c5 !important;
}
