$primary-color: #ec2a90;
$secondary-color: #122857;

.welcome-grid {
	text-align: center;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.data-grid {
	margin: 0 auto !important;
	padding-top: 50px !important;
}
.calender-header {
	background-color: #b7a9cf !important;
	display: flex !important;
	justify-content: space-between;
}
.year-select-box {
	background-color: white !important;
}

.input-from-text {
	height: 100px !important; // Adjust the height as needed
}
.secondary-button {
	background-color: $secondary-color !important;
	color: white !important;
}
.primary-button:hover {
	background-color: black !important;
	color: white !important;
}

.header-color {
	background-color: $primary-color !important;
	color: white !important;
}

.person-image-dashboard {
	height: 100px !important;
}
.report-grid {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.spinner-loading {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}
.report-div {
	padding-top: 50px !important;
}
.team-member-grid {
	background-color: #4700d5 !important;
	border-radius: 10% !important;
	display: flex !important;
	margin-bottom: 50px !important;

	justify-content: center !important;
	align-items: center !important;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.Volunteers-grid {
	background-color: #4292f9 !important;
	border-radius: 10% !important;
	display: flex !important;
	margin-bottom: 50px !important;

	justify-content: center !important;
	align-items: center !important;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.Doners-grid {
	background-color: #f8b53a !important;
	border-radius: 10% !important;
	display: flex !important;
	margin-bottom: 50px !important;

	justify-content: center !important;
	align-items: center !important;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.Donations-grid {
	border-radius: 10% !important;
	background-color: #e75656 !important;
	margin-bottom: 50px !important;
	display: flex !important;

	justify-content: center !important;
	align-items: center !important;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.volunteer-image {
	height: 80px !important;
	width: 80px !important;
	color: white !important;
}
.tables-grid {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.dashboard-card {
	width: 280px !important;
}
@media (min-width: 425px) and (max-width: 650px) {
	.dashboard-grid {
		margin-top: 70px !important;
	}
}
@media (min-width: 320px) and (max-width: 424px) {
	.leave-calender-text {
		font-size: 20px !important;
		width: 300px !important;
	}
}
.container-list {
	display: flex !important;
	justify-content: flex-end !important;
	padding: 1%;
}
.container-item {
	display: flex !important;
	justify-content: flex-end !important;
}
.align-center {
	align-content: center;
}
