$primary-color: #ec2a90;
$secondary-color: #122857;

.auth-layout-container {
	height: 100vh;
	overflow-x: auto;
	background-image: url("../common/icons/image-doctor-backgroud.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.notification-drawer-header{
	// margin-left: auto !important;
    // margin-right: auto !important;
	overflow-x: hidden !important;
    overflow-y: auto !important;
    height: 600px !important;
    width: 500px !important;
}
.app-layout-container {
	.company-title {
		font-size: 24px;
		box-sizing: border-box;
		font-weight: 600;
		text-align: left;
		line-height: normal;
	}

	.tool-bar-container {
		min-height: auto !important;
	}
}
.profile-img-log {
	width: 150px !important;
	height: 150px !important;
	border: 2px solid #f99117 !important;
	border-radius: 50% !important;

	margin: 30px auto !important;
}
.logo_grid {
	display: flex !important;
	flex-direction: column !important;
	width: 20% !important;
	margin: auto !important;
	padding: 50px 0px 50px 0px !important;
}
.cursur-pointer {
	cursor: pointer;
}
.submenu-container {
	margin-left: 50px !important;
}
.left-menu-container {
	width: 100%;
	margin-left: 40px !important;
	.menu-item-container {
		margin-bottom: 10px !important;
		width: 200px !important;
	}
}
.menu-list {
	word-wrap: break-word !important;
	padding: 1% !important;
}
.notification-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}
// .notification-drawer-container {
// 	width: 500px !important;
// }
.manage-align {
	align-items: center;
}
.arrow-icon {
	transform: rotate(90deg);
}
.auth-layout-container {
	height: 100vh;
	overflow-x: auto;
}

.app-layout-container {
	.company-title {
		font-size: 24px;
		box-sizing: border-box;
		font-weight: 600;
		text-align: left;
		line-height: normal;
	}

	.tool-bar-container {
		min-height: auto !important;
	}
}
.cursur-pointer {
	cursor: pointer;
}
.left-menu-container {
	// width: 100%;
	margin: 0 40px !important;
	.menu-item-container {
		margin-bottom: 16px !important;
	}
}
.manage-align {
	align-items: center;
}
.arrow-icon {
	transform: rotate(90deg);
}
.profile-img {
	height: 35px !important;
	width: 35px !important;
	border-radius: 50% !important;
	//   border: 2px solid red;
}
.img-logo {
	height: 100%;
	width: 100%;
}
.logo-icon {
	height: 70px !important;
}
.profile-res1 {
	display: none;
}
.header-profile {
	display: flex;
}
.dashBoard-header-menu {
	background-color: $primary-color !important;
}

@media (max-width: 650px) {
	.header-profile {
		display: contents;
	}
	.profile-res1 {
		display: block;
	}
	.menu-bar {
		margin-bottom: 50px !important;
	}
}
@media (max-width: 600px) {
}
.MenuTitile {
	// color: #9cdef7 !important;
	color: white !important;
	margin-top: 8px !important;
	font-weight: 600 !important;
	font-size: larger !important;
	text-transform: capitalize !important;
}
.MenuTitleSelected {
	color: $secondary-color !important;
	margin-top: 8px !important;
	font-weight: 600 !important;
	font-size: larger !important;
	text-transform: capitalize !important;
}
