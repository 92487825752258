.login {
	background-color:aliceblue !important;
	// height: 100vh !important;
}
.login-form {
	margin-top: -150px !important;
	width: 400px !important;
	box-sizing: border-box;
}
.sign-up-form{
	margin-top: -100px !important;
	width: 500px !important;
	}
.img-forget-password {
	height: 200px !important;
	// border-radius: 30% !important;
	// box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.forget-password-form{
	margin-top: -80px !important;
}
.forget-password-form {
	width: 30% !important;
}
.img-logo-login{
	height: 100% !important;
	width: 100% !important;
}
.forget-password {
	background-color: #f36b21 !important;
	height: 100vh !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important
  ;
}
.username-input {
	width: 100%;
}
.login-button {
	color: black !important;
	width: 50%;
	border-radius: 15px !important;
	height: 35px !important;
	background-color: white !important;
}
.custom-select-box {
	background-color: white !important;
}

.person-image {
	width: 100%;
	max-height: 100% !important;
}
.login-form-main {
	height: 100vh !important;
}
.sivabakkiyam-logo {
	width: 400px !important;
}
.person-image-head {
	height: 100vh !important;
}
.login-logo {

// height: 150px !important;
width: 350px !important;
	margin-bottom: 80px !important;
	margin-right: 50px !important;
}
.login-logo-img {
	width: 100% !important;
	height: auto !important;
}
.forget-password-button{
background-color: white !important;
color: black !important;
}

@media (min-width: 769px) and (max-width: 1024px) {
	.forget-password-form {
		width: 40% !important;
	}
}
@media (min-width: 425px) and (max-width: 768px) {
	.sign-up-form{
		margin-top: -100px !important;
		width: 400px !important;
		}
		.login-form {
			margin-top: -150px !important;
			width: 400px !important;
			box-sizing: border-box;
		}
	.login-image {
		display: none !important;
	}
	.login-form {
		width: 300px !important;
		box-sizing: border-box;
	}
	.sivabakkiyam-logo {
		width: 300px !important;
	}
	.forget-password-form {
		width: 55% !important;
	}
}
@media (min-width: 375px) and (max-width: 424px) {
	.sign-up-form{
		margin-top: -100px !important;
		width: 300px !important;
		}
		.forget-password-form {
			width: 60% !important;
		}
		.login-form {
			margin-top: -150px !important;
			width: 300px !important;
			box-sizing: border-box;
		}
	.login-image {
		display: none !important;
	}
	.login-form {
		width: 300px !important;
		box-sizing: border-box;
	}
	.sivabakkiyam-logo {
		width: 300px !important;
	}
}
@media (min-width: 320px) and (max-width: 374px) {
	.sign-up-form{
		margin-top: -100px !important;
		width: 300px !important;
		}
		
		.forget-password-form {
			width: 70% !important;
		}
			.login-form {
				margin-top: -150px !important;
				width: 300px !important;
				box-sizing: border-box;
			}
	.login-image {
		display: none !important;
	}
	.login-form {
		width: 300px !important;
		box-sizing: border-box;
	}
	.sivabakkiyam-logo {
		width: 300px !important;
	}
}
