.common-style {
	background-color: white !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.api-modal-container {
	min-width: 20vw;
	min-height: 10vh;
	border-radius: 10px;
	padding: 30px;
}
.img-preview {
	overflow: hidden !important;
}
.cancle-icon {
	color: #d32f2f !important;
	font-size: 30px !important;
}
.custom-button-grid {
	margin-top: -35px !important;
}
.cancle-button {
	margin-right: -58px !important;
}
