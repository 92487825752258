.profile-img-log {
  width: 150px !important;
  height: 150px !important;
  border: 2px solid #f99117 !important;
  border-radius: 50% !important;
}
.photo-icon {
  font-size: 40px !important;

  color: black;
}
.form-select {
  width: 100% !important;

}
.custom-button-photo {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-left: -20px !important;
}
.invoice-heading {
  color: #ef7025!important;
  // padding: 2% !important;
  font-size: 24px !important;
  text-align: center !important;
  font-weight: 600 !important;
  // margin-left: 150px !important;
}
.invoice-heading-address-col{
  width: 100% !important;
}
.invoice-heading-address {
  color: #ef7025!important;
  // padding: 0% 0% 0% 2% !important;
  font-size: 13px !important;
  text-align: center !important;
  font-weight: 600 !important;
}
.logo-col{
  display: flex !important;
  justify-content: center !important;
 
}
.img-print-logo{
  margin-top: 10px !important;
  height: 50px !important;
  width: 50px !important;
}
.main-spinner{
  display: flex !important;
  justify-content: center !important;
  margin-top: auto !important;
}
.invoice-heading-number{
  color: black !important;
 
  margin-top: 10px !important;
}
.phone-icon{
  height: 15px !important;
}
.heading-div{
  display: flex !important;
}
.invoice-heading-reg{
  text-align: center !important;
}
.invoice-content-row{
  border:1px solid black !important;
  margin-top: 30px !important;
  padding-bottom: 10px !important;
}
.invoice-content-body{
  border:1px solid black !important;
  border-top: none !important;
}
.receipt-no-row{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  width: 95% !important;
}
.receipt-text-box{
  border: 1px solid black !important;
  width: 100px !important;
  text-align: center !important;
  margin: auto !important;
  margin-top: -10px !important;
  background-color: white !important;
  border-radius: 5px !important;
  // margin-bottom: -100px !important;
}
.received-with-col{
  width: 95% !important;
  margin: auto !important;

}
.invoice-received-with{
  font-size: 16px !important;
  font-weight: 600 !important;
}
.invoice-received-with-adrress{
  font-size: 14px !important;
  font-weight: 500 !important; 
}
.invoice-heading-number{
  font-size: 11px !important;
  font-weight: 600 !important;
 
}
.invoice-PayerSignature{
  margin-left: 30px !important;
}
.cash-text-box{
  border: 2px solid black !important;
  width: 200px !important;
  height:40px !important;
  background-color: white !important;

}
.amount-text{
  text-align: left !important;
  margin-top: 5px !important;
  font-size: 18px !important;
  margin-left: 10px !important;
  
}
.cash-text-box-sub{
display: flex;
// justify-content: space-between;
}
.last{
  margin-top: 30px !important;
}
.rupee-icon{
  margin-top: -1px !important;
}
.cash-text-box-sub-1{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 50px !important;
  height: 38px !important;
  background-color: blue !important;
 
 
  
}
.invoice-received-for-text{
  font-size: 14px !important;
}

@media print {
  .hide-on-print {
    display: none !important;
  }
}
